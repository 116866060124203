import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from "@material-ui/core/IconButton";

import ToolTip from '../ToolTip/ToolTip';
// Import Styles
import inputStyle from '../../assets/jss/components/input';
import { SearchIcon } from '../SvgIcons/SvgIcons';
import { history } from '../../helpers';

/**
 * Search Input component
 * @class SearchInput
 * @extends {Component}
 */
class SearchInput extends Component {
    /**
     * Init Constructors
     * @param props
     */
    constructor(props) {
        super(props);
        //state declaration
        this.state = {
            value: this.props.value,
            typing: false,
            typingTimeout: 0
        };
        this.inputRef = createRef()
    }
    /**
     * Component Will Receive Props
     * @param {*} nextProps 
     * 
     * To update the Props before calling another render()
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { isLoading } = nextProps
        if (this.props.reset === true) {
            this.setState({ value: '' });
            clearTimeout(this.state.typingTimeout);
        }
        if (isLoading) {
            this.inputRef.current.blur()
        }
        this.setState({ value: nextProps.value });
    }
    /**
     * Handle TextField Events
     * @param {*} value 
     * @returns 
     */
    handleChange = value => event => {
        const self = this;
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            value: event.target.value,
            typing: false,
            // typingTimeout: setTimeout(function () {
            //     self.props.onInputChangeRequest(self.props.field, self.state.value);
            // }, 650)
        });
    };

    handleOnClick = () => {
        const self = this;
        self.props.onInputChangeRequest(self.props.field, self.state.value);

    }

    /**
     * On Enter
     * @param {*} event 
     */
    onEnter = (event) => {
        const self = this;
        if (event.keyCode === 13) {
            self.props.onInputChangeRequest(self.props.field, self.state.value);
        }
    }
    /**
     * Bind
     * HTML to DOM
     */
    render() {
        const { value } = this.state;
        let page = history.location.pathname.split('/')
        const { classes, placeholder, header, field, isHidden, isReadOnly } = this.props;
        let className = field === 'exploreSearch' || field === 'accordionSearch' ? `${classes.accordionsearchip} accordionSearch ` : classes.searchip;
        let setFullwidth = page.includes("table1") || page.includes('contractActivity') || page.includes('occupants') || field === 'exploreSearch' || field === 'accordionSearch' ? "100%" : '200px';
        setFullwidth = field === 'sno' ? '120px' : setFullwidth;
        return (

            <div className='searchbox' style={{ width: isHidden ? '' : setFullwidth }}>
                <Input
                    readOnly={isReadOnly}
                    inputRef={this.inputRef}
                    className={className}
                    value={value}
                    endAdornment={
                        this.state.value.length > 0 ?

                            <InputAdornment style={{ marginRight: '-6px' }}>
                                <ToolTip title="Search">
                                    <IconButton onClick={() => { this.handleOnClick() }} style={{ width: '24px', height: '24px', marginRight: 'unset' }}>
                                        <SearchIcon fill='#36aebe' width='16px' height='14px' />
                                    </IconButton>
                                </ToolTip>

                            </InputAdornment>
                            : ''
                    }
                    onChange={this.handleChange()}
                    onKeyDown={this.onEnter}
                    onBlur={this.onEnter}
                    placeholder={placeholder}
                    inputProps={{
                        'aria-label': `${header}`,
                        // onBlur: () => {
                        //     this.onEnter({ keyCode: 13 })
                        // }
                    }}
                />
        </div>
        );
    }
}

//default props
SearchInput.defaultProps = {
    label: '',
    value: '',
    placeholder: '',
    header: '',
    field: '',
    reset: false,
    classes: {},
    isLoading: false,
    onInputChangeRequest: () => { },
    onEnter: () => { },
    isHidden:  false,
    isReadOnly: false
}

//prop types
SearchInput.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    field: PropTypes.string,
    reset: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    onInputChangeRequest: PropTypes.func.isRequired,
    isHidden: PropTypes.bool,
    isReadOnly: PropTypes.bool
};

//export component
export default withStyles(inputStyle)(SearchInput);
