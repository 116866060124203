import { authHeader, globalVariable, handleResponse, handleDownloadResponse } from '../helpers';
import momentTZ from 'moment-timezone';

export const monitoringCycleService = {
    getMonitoringCycleLogs,
    getAllSiteLogs,
    create,
    checkDuplicate,
    clear,
    downloadCycle,
    dropTempTable,
    getFunderList
}

function getAllSiteLogs (skip, limit, orderBy, order, sFields, sValues) {
    let data = {
        fields: sFields,
        values: sValues,
        timeZone: momentTZ.tz.guess(true),
    };
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/monitoring-cycle/sitelogs?skip=${skip}&limit=${limit}&orderBy=${encodeURIComponent(orderBy)}&order=${order}`, requestOptions).then(handleResponse)
}

function getMonitoringCycleLogs (skip, limit, orderBy, order, sFields, sValues) {
    let data = {
        fields: sFields,
        values: sValues,
        timeZone: momentTZ.tz.guess(true),
    };
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/monitoring-cycle/logs?skip=${skip}&limit=${limit}&orderBy=${encodeURIComponent(orderBy)}&order=${order}`, requestOptions).then(handleResponse)
}

function create(formData) {

    let data = formData
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/monitoring-cycle/create`, requestOptions).then(handleResponse)
}

function checkDuplicate(formData) {
    let data = {
        ...formData
    }
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${globalVariable.apiURL}/monitoring-cycle/duplicateCheck`, requestOptions).then(handleResponse)
}

function clear(id) {

    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    }

    return fetch(`${globalVariable.apiURL}/monitoring-cycle/clear/${id}`, requestOptions).then(handleResponse)
}

function downloadCycle (data) {

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ headerCols: data}),
    };
    return fetch(`${globalVariable.ms_apiURL}/monitoring-cycle/download`, requestOptions).then(handleDownloadResponse);
}

function dropTempTable () {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    }
    return navigator.sendBeacon(`${globalVariable.apiURL}/clearCollection`,JSON.stringify(requestOptions));
}

function getFunderList () {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    }
    return fetch(`${globalVariable.apiURL}/monitoring-cycle/getFunderList`, requestOptions).then(handleResponse)
}