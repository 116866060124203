import React, { Component } from 'react';
import TableComponent from '../../components/Table/TableComponent';
import FieldFilter from "../../components/FieldFilter/FieldFilter";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import {history, globalVariable, filterSearchFields} from '../../helpers';
import pageStyle from "../../assets/jss/containers/common";
import { connect } from "react-redux";
import { configurationConstants  } from '../../helpers/appConstants';
// import {monitoringCycleActions} from '../../../../actions/monitoringCycleActions';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import { monitoringCycleActions } from '../../actions/monitoringCycle';
import { confActions } from '../../actions/configuration';

class AutoComplete extends Component {
    render() {
        const {suggestions, defaultValue} = this.props;
        return (
            <>
                <Autocomplete 
                    renderInput={(params) => <TextField {...params} variant="standard" size="small" sx={{ marginTop: 1.80 }} />}
                    disableClearable
                    sx={{ width: 150, height: 50 }}
                    defaultValue={defaultValue}
                    options={suggestions}
                />
            </>
        )
    }
}

class EditTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reportYear: "",
            fieldFilter: false,
        }
    }

    UNSAFE_componentWillMount() {

        this.props.getAll(1, globalVariable.tableRowSize, 'report_name', 'asc', '', '');
        this.props.getConfiguration(configurationConstants.monitoringType);

        setTimeout(() => {
          this.props.getFieldFilter('monitoringCycle');
        }, 100);
    }

    handleFieldFilterActionClick = () => {
        this.setState({
            fieldFilter: !this.state.fieldFilter
        });
    };

    handleFieldFilterOnChange = (key, value) => {
        if (!value) {
            const updatedColumns = this.props.visibleColumns.filter((value) => {
                return value !== key;
            });
            this.props.changeFieldFilter("monitoringCycle", updatedColumns);
        } else {
            this.props.changeFieldFilter("monitoringCycle", [...this.props.visibleColumns, key]);
        }
    }

    handleFieldFilterOnSave = () => {
        this.props.updateFieldFilter("monitoringCycle", this.props.visibleColumns, () => {
            // on api call back close the popup and call the table view api again
            this.setState({ fieldFilter: false });
            const { monitoringCycle } = this.props;
            const { page: {skip, limit, count, fieldFilters: { headerCols, fieldCols, searchCols } } } = monitoringCycle;
            this.props.getAll('report_name', 'asc', '', '');
        });
    }

    handleFieldFilterOnClose = () => {
        // reset to current table column values if user not save the changes
        this.props.changeFieldFilter("monitoringCycle", this.props.monitoringCycle.page.fieldFilters.visibleColumns);
        // close the popup
        this.setState({ fieldFilter: false });
    }

    render() {
        const {location: {pathname}} = this.props;
        const {monitoringType, monitoringCycle} = this.props;
        const { page: {skip, limit, count, fieldFilters: { headerCols, fieldCols, searchCols } } } = monitoringCycle;
        const { columnsMap, defaultCols, visibleColumns } = this.props;
        const { logList, data } = monitoringCycle;
        const {reportYear, fieldFilter} = this.state;
        const reportYears = [];
        let year = new Date().getFullYear();

        for (let i=0; i < 10; i++) {
            if (i == 0) {
                reportYears.push(new Date().getFullYear());
            } else {
                reportYears.push(year+1);
                year = year + 1;
            }
        }
        
        return (
            <>
             <ContentWrapper
              title="INSPECTION"
              pageAccess={["FILTER","CLEAR","ADD","DOWNLOAD"]}
              needPadding={true}
              handleFieldFilterActionClick={this.handleFieldFilterActionClick}
              handleClearFilterActionClick={() => {
                history.replace(`${pathname}#clear`);
              }}
             >
                <TableComponent 
                    { ...{
                        page: skip,
                        rowsPerPage: limit,
                        count: count,
                        header: headerCols,
                        field: fieldCols,
                        search: searchCols,
                        componentMap: {
                            "year": () => <AutoComplete suggestions={reportYears} defaultValue={reportYear}/>,
                            "monitoringType": () => <AutoComplete suggestions={monitoringType} defaultValue={""}/>,
                            "inspectingFunder" : () => <> </>
                        },
                        fieldComponentOnChange: () => {},
                        actions: [
                            "FILTER",
                            "UPLOAD_BULK_UTILITYALLOWANCE",
                            "HELP"
                        ],
                        userOrgId: 1,
                        userRole: "superFunder",
                        currentPage: "Inspection Page",
                        order: "asc",
                        orderBy: "name",
                        data: logList
                        // data: [
                        //     {
                        //         "sno": 1,
                        //         "year": "",
                        //         "monitoringCycle": "B",
                        //         "projectName": "Villa Capri",
                        //         "contractor/owner": "Amanda Santo",
                        //         "siteName": "Villa Capri",
                        //         "monitoringType": "",
                        //         "inspectingFunder": [
                        //             {
                        //               "id": 1,
                        //               "label": "WSHFC"
                        //             },
                        //             {
                        //               "id": 2,
                        //               "label": "Commerce"
                        //             },
                        //             {
                        //               "id": 4,
                        //               "label": "KC"
                        //             }
                        //         ],
                        //         "funderName1": "Washington State Housing Finance Commission",
                        //         "ContractOID1": "05-32",
                        //         "funderName2": "Department of Commerce, Housing Trust Fund",
                        //         "ContractOID2": "06-94100-030",
                        //         "funderName3": "King County Housing Finance Program",
                        //         "ContractOID3": "204",
                        //         "homeState": "",
                        //         "homeCity": "",
                        //         "homeCounty": "Yes",
                        //         "nhtf": ""
                        //       },
                        // ]
                    }}
                    paginationVisible={true}
                    />
                     <FieldFilter
                        open={fieldFilter}
                        handleOnClose={this.handleFieldFilterOnClose}
                        handleOnChange={this.handleFieldFilterOnChange}
                        handleOnSave={this.handleFieldFilterOnSave}
                        columnsMap={columnsMap}
                        visibleColumns={visibleColumns}
                        disabledCols={defaultCols}
                    />
             </ContentWrapper>
            </>
        )
    }
}

AutoComplete.defaultProps = {
    suggestions: [],
    defaultValue: ''
}

/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const columnsData = state.configuration.fieldFilter['monitoringCycle'] || {};
    return {
        monitoringCycle : state.monitoringCycle,
        monitoringType: state.configuration.configurations[configurationConstants.monitoringType] || [],
        columnsMap: columnsData.columnsMap || {},
        defaultCols: columnsData.defaultCols || [],
        visibleColumns: columnsData.visibleColumns || []
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
       getAll: (skip, limit, orderBy, order, sFields, sValues) => dispatch(monitoringCycleActions.getMonitoringCycleLogs(skip, limit, orderBy, order, sFields, sValues)),
       getConfiguration: (type) => dispatch(confActions.getConfiguration(type)),
       getFieldFilter: (table) => dispatch(confActions.getFieldFilter(table)),
       changeFieldFilter: (table, visibleColumns) => dispatch(confActions.changeFieldFilter(table, visibleColumns)),
       updateFieldFilter: (table, visibleColumns, clback) => dispatch(confActions.updateFieldFilter(table, visibleColumns, clback)),
    }
};

/**
 * EditTable Component
 */

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(EditTable));



