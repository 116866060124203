import React, { Component, createRef } from 'react';
import TableComponent from '../../components/Table/TableComponent';
import FieldFilter from "../../components/FieldFilter/FieldFilter";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import {history, globalVariable, filterSearchFields} from '../../helpers';
import pageStyle from "../../assets/jss/containers/common";
import { connect } from "react-redux";
import { configurationConstants  } from '../../helpers/appConstants';
import { searchActions } from '../../actions/search';
import AlertDialog from '../../components/AlertDialog/AlertDialog';
import { alertActions } from '../../actions/alert';
import SnackBar from '../../components/SnackBar/SnackBar';
import {monitoringCycleService} from '../../services/monitoringCycle';
import PageLoader from '../../components/PageLoader/PageLoader';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { monitoringCycleActions } from '../../actions/monitoringCycle';
import { confActions } from '../../actions/configuration';
import DeleteDialog from '../../components/DeleteDialog/DeleteDialog';
import InspectionTable from '../../components/Table/InspectionTable';
import { v4 as uuidv4 } from 'uuid';


/**
 * AutoComplete child component
 *
 * @class AutoComplete
 * @extends {Component}
 */

class AutoComplete extends Component {
    render() {
        const {suggestions, defaultValue, handleChange, name, error, isReadOnly} = this.props;
        return (
            <>
                <Autocomplete 
                    renderInput={(params) => <TextField 
                         {...params} 
                         variant="standard" 
                         size="small" 
                         sx={{ marginTop: 1.80 }} 
                         error={error}
                         helperText={error ? `This field is Required` : ""}
                    />}
                    sx={{ width: 150, height: 50  }}
                    value={defaultValue || ""}
                    options={suggestions}
                    isReadOnly={isReadOnly}
                    onChange={(event, newValue) => handleChange(event, newValue, name)}
                />
            </>
        )
    }
}

/**
 * Inspection Main Component
 *
 * @class Inspection
 * @extends {Component}
 */

class Inspection extends Component {

    /**
     * Constructor
     */
    constructor(props) {
        super(props);
        this.filterRef = createRef();
        this.state = {
            reportYear: "",
            monitoringType: "",
            fieldFilter: false,
            inspectingFunder: "",
            rowIndex: null,
            selectedRow: null,
            data: [],
            siteData: [],
            checkedData: [],
            isAddOpen: false,
            errorMsg: '',
            isSnackVisible: false,
            originalRow: null,
            isPageOpen: false,
            pendingNavigation: null,
            values: {
                year : null,
                monitoringType : null,
                inspectingFunderValue : null
            },
            isLoading: false,
            isCheckBoxLoading: false,
            tableLoader : false
        }
        this.unblock = null;
    }

     /**
     * Component Will Mount
     */
    UNSAFE_componentWillMount() {

        this.props.getAll(1, globalVariable.tableRowSizeInspection, 'projectName', 'asc', '', '');
        this.props.getFunderList();
        setTimeout(() => {
          this.props.getFieldFilter('monitoringCycle');
        }, 100);
    }

    /**
     * Component Did Mount
     */
    componentDidMount () {        
        // this.props.getConfiguration(configurationConstants.monitoringType);
       
        window.addEventListener('beforeunload', this.handleBeforeUnload);
        this.unblock = this.props.history.block((tx) => {
            if (this.state.rowIndex != null) {
              this.setState({ isPageOpen: true, pendingNavigation: tx });
              return false;
            }
            return true;
          });
    }

     /**
     * Component will UnMount
     */

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
        if (this.unblock) {
          this.unblock();
        }
      }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { monitoringCycle ,siteLogs} = nextProps;
        let { logList   } = monitoringCycle;
        let { data } = siteLogs;
       
        this.setState({data: logList})
        this.setState({siteData: siteLogs.logList })
    }

     /**
     * Component Did update
     */
     componentDidUpdate() {
        //To clear the filters in the table
        switch (this.props.location.hash) {
            case "#clear":
                this.handleClearFilterActionClick();
                history.replace(this.props.location.pathname);
                break;
            default:
                break;
        }
    }


    onDropDownChangeRequest = (searchField, searchValue) =>{
       
        this.setState({ reset: false });
        let searchKey = searchValue == 'All' || searchValue == 0 ? '' : searchValue;
        const { monitoringCycle, searchData } = this.props;
        let { sFields, sValues } = searchData;

        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '' || searchKey == null) {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }

        this.props.searchActions({ sFields, sValues });
        this.props.getAll(1, globalVariable.tableRowSizeInspection, monitoringCycle.page.orderBy, monitoringCycle.page.order, sFields, sValues, false);

    }

    handleBeforeUnload = (event) => {
        monitoringCycleService.dropTempTable()
    };
    

    /**
     * Handle Table Search Input Change Event
     * @param {*} searchField 
     * @param {*} searchKey 
     */
    onInputChangeRequest = (searchField, searchKey) => {
        this.setState({ reset: false, selectedRow: null });
        const { monitoringCycle, searchData } = this.props;
        let { sFields, sValues } = searchData;

        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }

        this.props.searchActions({ sFields, sValues });
        this.props.getAll(1, globalVariable.tableRowSizeInspection, monitoringCycle.page.orderBy, monitoringCycle.page.order, sFields, sValues, false);
    };

    /**
     * Handle Table Search Input Change Event
     * @param {*} searchField 
     * @param {*} searchKey 
     */
    onInputSiteChangeRequest = (searchField, searchKey) => {
        this.setState({ reset: false });
        const { siteLogs, siteSearchData } = this.props;
        let { sFields, sValues } = siteSearchData;

        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }

        this.props.siteSearchActions({ sFields, sValues });
        this.setState({tableLoader:true})
        this.props.getSites(1, globalVariable.tableRowSizeInspectionSite, siteLogs.page.orderBy, siteLogs.page.order, sFields, sValues, ()=>{
            setTimeout(() => {
                this.setState({tableLoader:false});
            }, 1000);
        });
    };

    /**
     * Handle Table Tagination Event
     * @param {*} skip 
     * @param {*} limit 
     */
    onChangePageTable = (skip, limit) => {
        const { monitoringCycle, searchData: { sFields, sValues } } = this.props;
        this.props.getAll(skip, limit, monitoringCycle.page.orderBy, monitoringCycle.page.order, sFields, sValues, false);
    };

     /**
     * Handle Table Tagination Event for All Sites
     * @param {*} skip 
     * @param {*} limit 
     */
     onChangePageTableAllSites = (skip, limit) => {
        const { siteLogs, searchData: { sFields, sValues } } = this.props;
        this.props.changeCheckedData([...this.state.checkedData]);
        this.props.getSites(skip, limit, siteLogs.page.orderBy, siteLogs.page.order, sFields, sValues, ()=> {
            
            this.props.siteLogs.checkedData.forEach((cData,cIndex) => {
                this.props.siteLogs.logList.forEach((logList,index) => {
                    if (cData['siteKey'] == logList['siteKey'] && cData['projectKey'] == logList['projectKey']) {
                        logList['isChecked'] = cData['isChecked']
                    }
                })
            });

            this.props.changeSiteData(this.props.siteLogs.logList)
        });
    };

     /**
     * Handle Table Sort Request Event
     * @param {*} orderBy 
     * @param {*} order 
     */
     onSortRequest = (orderBy, order) => {
        const { monitoringCycle,searchData: { sFields, sValues } } = this.props;
        this.props.getAll(monitoringCycle.page.skip, monitoringCycle.page.limit, orderBy, order, sFields, sValues, false);
    };

     /**
     * Handle Table Sort Request Event
     * @param {*} orderBy 
     * @param {*} order 
     */
     onSiteSortRequest = (orderBy, order) => {
        const { siteLogs, siteSearchData: { sFields, sValues } } = this.props;
        this.props.getSites(siteLogs.page.skip, siteLogs.page.limit, orderBy, order, sFields, sValues, ()=>{});
    };

    /**
     * Handle Clear Fileter Action
     * 
     */
    handleClearFilterActionClick = () => {
        const { monitoringCycle} = this.props;
        this.props.searchActions({ sFields: [], sValues: [] });
        this.filterRef.current.handleClearFilters();
        this.props.getAll(monitoringCycle.page.skip, monitoringCycle.page.limit, monitoringCycle.page.orderBy, monitoringCycle.page.order, [], []);
    };

    handleFieldFilterActionClick = () => {
        this.setState({
            fieldFilter: !this.state.fieldFilter
        });
    };

    handleRowClick = (index) => {
        this.setState({
            selectedRow: index
        })
    }

    handleFieldFilterOnChange = (key, value) => {
        if (!value) {
            const updatedColumns = this.props.visibleColumns.filter((value) => {
                return value !== key;
            });
            this.props.changeFieldFilter("monitoringCycle", updatedColumns);
        } else {
            this.props.changeFieldFilter("monitoringCycle", [...this.props.visibleColumns, key]);
        }
    }

    handleFieldFilterOnSave = () => {
        this.props.updateFieldFilter("monitoringCycle", this.props.visibleColumns, () => {
            // on api call back close the popup and call the table view api again
            this.setState({ fieldFilter: false });
            const { monitoringCycle,  searchData: { sFields, sValues } } = this.props;
            const { page: {skip, limit, count, orderBy, order, fieldFilters: { headerCols, fieldCols, searchCols } } } = monitoringCycle;
            this.props.getAll(skip, limit, orderBy, order, sFields, sValues);
        });
    }

    handleFieldFilterOnClose = () => {
        // reset to current table column values if user not save the changes
        this.props.changeFieldFilter("monitoringCycle", this.props.monitoringCycle.page.fieldFilters.visibleColumns);
        // close the popup
        this.setState({ fieldFilter: false });
    }

    handleTableDeleteAction = (id, item) => {
       let { monitoringCycle, searchData: { sFields, sValues } } = this.props;
       this.props.clear(id, () => {
        this.setState({ selectedRow: null, rowIndex: null })
        this.props.getAll(monitoringCycle.page.skip, monitoringCycle.page.limit, monitoringCycle.page.orderBy, monitoringCycle.page.order, sFields, sValues);
        this.props.clearDuplicate();
       });
    }

    handleTableClearAction = (id, item ) => {
        let originalRow = this.props.monitoringCycle.tempLogList[this.state.rowIndex]
        const updatedData = this.state.data.map((row, index) =>
            index === this.state.rowIndex ? originalRow : row
        );

        this.setState({data: [...updatedData], originalRow: null, rowIndex:null});
        this.props.change([...updatedData])
        this.props.clearDuplicate();
    }

    handleDuplicateErrors = () => {
        const { isDuplicateError, isDuplicateIFError, duplicateRecords, duplicateInspectingRecords } = this.props.monitoringCycle;
        let errorMsg = '';
        let isSnackVisible = true;
    
        if (isDuplicateError && isDuplicateIFError) {
            const dupSlno = duplicateRecords.map(x => x.sno).join('');
            const dupIfSno = duplicateInspectingRecords.map(x => x.sno).join('');
    
            errorMsg = `Inspection Error(s): Kindly fix the following error(s) to save :<ol><li>Duplicate inspection entry has been found for the site in the sl.no “${dupSlno}”</li> <li> The inspecting funder has an inspection already defined for the site in sl.no “${dupIfSno}”</li></ol>
            `;
        } else if (isDuplicateError) {
            const dupSlno = duplicateRecords.map(x => x.sno).join('');
            
            errorMsg = `Inspection Error(s): Kindly fix the following error(s) to save : <ol> <li> Duplicate inspection entry has been found for the site in the sl.no “${dupSlno}”<li> </ol>`;
        } else if (isDuplicateIFError) {
            const dupIfSno = duplicateInspectingRecords.map(x => x.sno).join('');
            
            errorMsg = `Inspection Error(s): Kindly fix the following error(s) to save :<ol><li> The inspecting funder has an inspection already defined for the site in sl.no “${dupIfSno}” </li></ol>`;
        }
    
        this.setState({
            isSnackVisible,
            errorMsg
        });
    }

    _saveItem = (saveObj, mandatoryValidation) => {
        let { monitoringCycle, searchData: { sFields, sValues } } = this.props;
        const isUpdate = saveObj['isNew'] && mandatoryValidation;
        this.props.create([{ ...saveObj, isUpdate }]);
        this.setState({ rowIndex: null });
        this.props.getAll(monitoringCycle.page.skip, monitoringCycle.page.limit, monitoringCycle.page.orderBy, monitoringCycle.page.order, sFields, sValues);
        // this.props.getAll(1, globalVariable.tableRowSizeInspection, 'projectName', 'asc', '', '');
        this.props.clearDuplicate();
    };

    

    handleSaveAction = (item, rowIndex, callBack) => {
        let data = this.state.data;
        let saveObj = data && data.length > 0 && data[rowIndex];

        let skipValidationForOldEntry = item['isUpdated'] && (!saveObj['year'] && !saveObj['inspectingFunderValue'] && !saveObj['monitoringType']);
        let mandatoryValidation = saveObj['year'] && saveObj['inspectingFunderValue'] && saveObj['monitoringType'];

        if (mandatoryValidation || skipValidationForOldEntry) {
            this.props.change(this.state.data);
            this.props.duplicateCheck(item, () => {
                if (!this.props.monitoringCycle.isDuplicateError && !this.props.monitoringCycle.isDuplicateIFError) {
                    this._saveItem(saveObj, mandatoryValidation)
                    callBack();
                } else {
                   this.handleDuplicateErrors();
                   this.props.clearDuplicate();
                }
            });
        }
    }

    handleSaveActionOrg = (item, rowIndex, callBack) => {
        let data = this.state.data;
        let { monitoringCycle, searchData: { sFields, sValues } } = this.props;
        let saveObj = data && data.length > 0 && data[rowIndex];

        let skipValidationForOldEntry =  item['isUpdated'] == true && (saveObj['year'] == null || saveObj['year'] == "") && (saveObj['inspectingFunderValue'] == null || saveObj['inspectingFunderValue'] == "")  && (saveObj['monitoringType'] == null || saveObj['monitoringType'] == "")

        let mandatoryValidation = (saveObj['year'] != null && saveObj['year'] != "") && (saveObj['inspectingFunderValue'] != null && saveObj['inspectingFunderValue'] != "")  && (saveObj['monitoringType'] != null && saveObj['monitoringType'] != "");

        if (mandatoryValidation || skipValidationForOldEntry) {
            this.props.change(this.state.data)
           
            this.props.duplicateCheck(item, () => {
                 
                if (!this.props.monitoringCycle.isDuplicateError && !this.props.monitoringCycle.isDuplicateIFError) {
                    this.props.create([{
                         ...saveObj,
                         isUpdate : (saveObj['isNew'] == true && mandatoryValidation)
                     }]);
                     this.setState({rowIndex: null})
                     this.props.getAll(monitoringCycle.page.skip, monitoringCycle.page.limit, monitoringCycle.page.orderBy, monitoringCycle.page.order, sFields, sValues);
                     // this.props.getAll(1, globalVariable.tableRowSizeInspection, 'projectName', 'asc', '', '');
                     this.props.clearDuplicate();
                     callBack();
                } else {
                    let dupSlno = "";
                    let dupIfSno = '';
                    
                    if (this.props.monitoringCycle.isDuplicateError && this.props.monitoringCycle.isDuplicateIFError) {
                        
                        this.props.monitoringCycle.duplicateRecords.map((x) => dupSlno += x.sno);
                        this.props.monitoringCycle.duplicateInspectingRecords.map((x) => dupIfSno += x.sno );

                        this.setState({
                            isSnackVisible : true,
                            errorMsg: `
                            Inspection Error(s): Kindly fix the following error(s) to save : <br/>
                            Duplicate inspection entry has been found for the site in the sl.no “${dupSlno}” <br/>
                            The inspecting funder has an inspection already defined for the site in sl.no “${dupIfSno}” 
                        `
                        })
                        // this.props.alert(`
                        //     Inspection Error(s): Kindly fix the following error(s) to save :
                        //     Duplicate inspection entry has been found for the site in the sl.no “${dupSlno}”
                        //     The inspecting funder has an inspection already defined for the site in sl.no “${dupIfSno}” 
                        // `)
                    } else if(this.props.monitoringCycle.isDuplicateError) {

                        this.props.monitoringCycle.duplicateRecords.map((x) => dupSlno += x.sno);
                        this.setState({
                            isSnackVisible : true,
                            errorMsg: `
                            Inspection Error(s): Kindly fix the following error(s) to save : <br/>
                            Duplicate inspection entry has been found for the site in the sl.no “${dupSlno}” 
                        `
                        })
                        // this.props.alert(`
                        //     Inspection Error(s): Kindly fix the following error(s) to save :
                        //     Duplicate inspection entry has been found for the site in the sl.no “${dupSlno}” 
                        // `)
                        
                    } else if (this.props.monitoringCycle.isDuplicateIFError) {
                        this.props.monitoringCycle.duplicateInspectingRecords.map((x) => dupIfSno += x.sno )
                        this.setState({
                            isSnackVisible : true,
                            errorMsg: `
                            Inspection Error(s): Kindly fix the following error(s) to save : <br/>
                            The inspecting funder has an inspection already defined for the site in sl.no “${dupIfSno}” 
                        `
                        })
                        // this.props.alert(`
                        //     Inspection Error(s): Kindly fix the following error(s) to save :
                        //     The inspecting funder has an inspection already defined for the site in sl.no “${dupIfSno}” 
                        // `)
                    }
                    this.props.clearDuplicate();
                }

            });
            
        }
        
    }

    handleCancelAction = (item, rowIndex) => {
        this.setState({rowIndex: null})
    }

    handleAddActionClick = () => {
        const { siteLogs, siteSearchData: { sFields, sValues } } = this.props;
        this.setState({ isLoading: true })
        this.props.getSites(1, globalVariable.tableRowSizeInspectionSite, 'projectName', 'asc', sFields || [], sValues || [], () => {
            this.setState({ isAddOpen: true, isLoading: false })
        });
        this.props.changeCheckedData([])
       
    }

    handleAddSiteActionClick = () => {
        this.setState({
            isAddOpen: false,
            data: [
                ...this.state.checkedData,
                ...this.state.data
            ]
        })
        const checkedData = this.state.checkedData.map((x) => {
            return {
                ...x,
                recordId: uuidv4()
            }
        })
        this.props.changeCheckedData([])
        this.props.create([...checkedData])
        this.props.change([...checkedData,...this.state.data]);
        this.props.changeTemp([...checkedData,...this.state.data]);
        this.props.changePageDetails({
            count: this.props.monitoringCycle.page.count + checkedData.length,
            limit: this.props.monitoringCycle.page.limit + checkedData.length
        })
        this.setState({ checkedData: []  })
       
    }

    handleDeleteAction = () => {
        this.setState({ isPageOpen: false });
        if (this.state.pendingNavigation) {
          this.unblock();
          this.props.history.push(this.state.pendingNavigation.pathname);
        }
      };

    handleDownloadAction = () => {
        const { monitoringCycle } = this.props;
        const fieldFilters = monitoringCycle.page ? monitoringCycle.page.fieldFilters : {};
        this.props.download(fieldFilters.headerCols)
    }

    handleChange  = (event, newValue, name) => {
        this.setState({
            ...this.state,
            [name]: newValue
        })
    }

    handleFilterRowClick = () => {
        const { rowIndex, values } = this.state;
        const { isDuplicateError, isDuplicateIFError } = this.props.monitoringCycle;
    
        // Check if any mandatory fields are empty
        const isMandatoryEmpty = rowIndex !== null &&
            (Object.values(values).some(value => value === null || value === ""));
    
        // Check if any mandatory fields are filled and others are empty
        const isMandatoryFilled = rowIndex !== null &&
            Object.values(values).some(value => value !== null && value !== "") &&
            Object.values(values).some(value => value === null || value === "");
    
        const isError = isMandatoryEmpty || isDuplicateError || isDuplicateIFError;
    
        if (isMandatoryEmpty) {
            this.setState({
                isSnackVisible: true,
                errorMsg: `Kindly fix the error(s) to proceed`
            });
        } else if (isError || rowIndex !== null) {
            this.setState({
                isSnackVisible: true,
                errorMsg: `Please save or cancel the updated inspection details of the site in the sl.no “${rowIndex+1}” to proceed`
            });
        }
    }

    handleFilterRowClickOrg = () => {
        let isMandatory = (this.state.rowIndex != null) && Object.values(this.state.values).some((value) => (value !== null || value !== ""))  &&
        Object.values(this.state.values).some((value) => (value === null || value == ""))

        let isError =  isMandatory || this.props.monitoringCycle.isDuplicateError || this.props.monitoringCycle.isDuplicateIFError;

        if (isMandatory) {
            this.setState({
                isSnackVisible : true,
                errorMsg: `Kindly fix the error(s) to proceed`
            })
            return;
        }

        if(isError || this.state.rowIndex != null) {
            this.setState({
                isSnackVisible : true,
                errorMsg: `Please save or cancel the updated inspection details of the site in the sl.no “${this.state.rowIndex+1}” to proceed`
            })
        }
        
    }

    handleCheckBoxAction = (isChecked, rowIndex, item) => {

        this.setState(prevState => ({
            siteData : prevState.siteData.map((checkbox,index) =>
              index === rowIndex
                ? { ...checkbox, isChecked: isChecked }
                : checkbox
            )
        }));

        if (isChecked) {

            let updatedItem = { ...item, isChecked: isChecked }
            // this.setState( { checkedData: [ ...this.state.checkedData, updatedItem] } )

            this.setState(prevState => ({
                checkedData : [ ...prevState.checkedData, updatedItem]
            }))

        } else {

            const removeIndex = this.state.checkedData.findIndex(element => element['siteKey'] === item['siteKey'])
            if (removeIndex > -1) {
                let updatedCheckedData =  [...this.state.checkedData];
                updatedCheckedData.splice(removeIndex,1);
                // this.setState({
                //     checkedData : [...updatedCheckedData]
                // })

                this.setState(prevState => ({
                    checkedData : [ ...updatedCheckedData]
                }))
            }
        }
    }


    handleFieldComponentOnChange = (e, newValue, name, rowIndex, clback) => {

        name = name === 'inspectingFunder' ? 'inspectingFunderValue' : name;

        const updatedRows = this.state.data.map((row, index) =>
            index === rowIndex ? { ...row, [name]: newValue } : row
        );
        let rowObjValue = {};
        let rowObj = this.state.data[rowIndex];

        if (rowObj['year'] !== null && rowObj['monitoringType'] !== null && rowObj['inspectingFunderValue'] !== null) {
            rowObjValue = {
            year: rowObj['year'],
            monitoringType: rowObj['monitoringType'],
            inspectingFunderValue: rowObj['inspectingFunderValue']
            };
        }

        this.setState({
            ...this.state,
            rowIndex: rowIndex,
            data: updatedRows,
            values: {
            ...this.state.values,
            ...rowObjValue,
            [name]: newValue
            }
        });

        clback(rowIndex);
    }

    getReportYears = () => {
        let reportYears = [];
        let year = new Date().getFullYear();

        for (let i=0; i < 10; i++) {
            if (i == 0) {
                reportYears.push(new Date().getFullYear());
            } else {
                reportYears.push(year+1);
                year = year + 1;
            }
        }

        return reportYears;
    }

    getRenderInfo = () => {
        let isUpdated = this.state.rowIndex != null && this.state.data[this.state.rowIndex]['isUpdated'];

        let isUpdatedAndAllEmpty = isUpdated && Object.values(this.state.values).every((value) => (value === null || value === ''));
       
        let isMandatory =  isUpdatedAndAllEmpty ? false : Object.values(this.state.values).some((value) => (value !== null || value !== ""))  &&
        Object.values(this.state.values).some((value) => (value === null || value == ""));

        let isEnableSave = isUpdatedAndAllEmpty || (this.state.rowIndex != null && Object.values(this.state.values).every((value) => value !== null));
        let isEnableCancel = Object.values(this.state.values).every((value) => value === null) || Object.values(this.state.values).some((value) => value !== null);

        let isError = isUpdatedAndAllEmpty ? false : (this.state.rowIndex != null && (isMandatory || this.props.monitoringCycle.isDuplicateError || this.props.monitoringCycle.isDuplicateIFError));

        return {
            isUpdated,
            isUpdatedAndAllEmpty,
            isMandatory,
            isEnableSave,
            isEnableCancel,
            isError
        }
    }
    
    render() {
        let { monitoringType, 
              monitoringCycle,  
              siteLogs, 
              siteSearchData, 
              searchData: { sFields, sValues },
              location: {pathname},
              columnsMap, 
              defaultCols, 
              visibleColumns 
            } = this.props;

        const { page: {skip, limit, count, orderBy, order, lastPage ,fieldFilters: { headerCols, fieldCols, searchCols } } } = monitoringCycle;
        sFields = Array.isArray(sFields) ? sFields : [];
        sValues = Array.isArray(sValues) ? sValues : [];

        const { logList, data, isLoading } = monitoringCycle;
        const {reportYear, fieldFilter, isAddOpen, isPageOpen} = this.state;
        const reportYears = this.getReportYears();

        let monitoringTypeAltered = monitoringType.map((x) => { return {id: x.key, label: x.name} });

        let {
            isMandatory,
            isEnableSave,
            isEnableCancel,
            isError
        } = this.getRenderInfo();
        
        return (
            <>
             <ContentWrapper
              title="INSPECTION"
              pageAccess={["FILTER","CLEAR","ADD","DOWNLOAD"]}
              needPadding={false}
              handleAddActionClick={ (isError || this.state.rowIndex != null )? this.handleFilterRowClick: this.handleAddActionClick}
              handleDownloadActionClick={ (isError || this.state.rowIndex != null )? this.handleFilterRowClick: this.handleDownloadAction}
              handleFieldFilterActionClick={ (isError || this.state.rowIndex != null )? this.handleFilterRowClick: this.handleFieldFilterActionClick}
              handleClearFilterActionClick={ (isError || this.state.rowIndex != null )? this.handleFilterRowClick: () => {
                history.replace(`${pathname}#clear`);
              }}
             >
             <div style={{padding: '0 10px', border: 'none !important'}}>
                <InspectionTable
                    page={skip}
                    rowsPerPage={limit}
                    count={count}
                    header={headerCols}
                    field={fieldCols}
                    search={searchCols}
                    stickyHeaders={[]}
                    isLoading={isLoading}
                    noDataErrorMessage="No Records Found"
                    funderList={monitoringCycle.dropDownFunderList}
                    ref={this.filterRef} 
                    componentMap={{
                    "year": ({ data, rowIndex, handleChange, name, isReadOnly }) => (
                        <AutoComplete
                        suggestions={reportYears}
                        defaultValue={data['year'] || ""}
                        name="reportYear"
                        handleChange={handleChange}
                        error={this.state.rowIndex !== null && rowIndex === this.state.rowIndex && isMandatory && !this.state.values[name]}
                        isReadOnly={isReadOnly}
                        />
                    ),
                    "monitoringType": ({ data, rowIndex, handleChange, name, isReadOnly }) => (
                        <AutoComplete
                        suggestions={[
                            { id: 1, label: 'In cycle' },
                            { id: 2, label: 'Placed in Service' },
                            { id: 3, label: 'Out of cycle' },
                            { id: 4, label: 'Risk' }
                        ]}
                        defaultValue={data['monitoringType']}
                        name="monitoringType"
                        handleChange={handleChange}
                        error={rowIndex === this.state.rowIndex && isMandatory && !this.state.values[name]}
                        isReadOnly={isReadOnly}
                        />
                    ),

                    "inspectingFunder": ({ suggestions, data, rowIndex, name, handleChange, isReadOnly }) => {
                        name = name === 'inspectingFunder' ? 'inspectingFunderValue' : name;
                        return (
                        <AutoComplete
                            name="inspectingFunderValue"
                            suggestions={suggestions}
                            defaultValue={data['inspectingFunderValue']}
                            handleChange={handleChange}
                            error={rowIndex === this.state.rowIndex && isMandatory && !this.state.values[name]}
                            isReadOnly={isReadOnly}
                        />
                        );
                    }
                    }}
                    fieldComponentOnChange={(e, newValue, name, rowIndex, clback) => this.handleFieldComponentOnChange(e, newValue, name, rowIndex, clback)}
                    value={this.state.inspectingFunder}
                    selectedRow={this.state.selectedRow}
                    actions={["CANCEL", "SAVE", "DELETE"]}
                    currentPage="Inspection Page"
                    order={order}
                    orderBy={orderBy}
                    data={this.state.data}
                    onSortRequest={(isError || this.state.rowIndex != null )? this.handleFilterRowClick: this.onSortRequest}
                    onInputChangeRequest={this.onInputChangeRequest}
                    onDropDownChangeRequest={this.onDropDownChangeRequest}
                    handleSaveAction={this.handleSaveAction}
                    handleCancelAction={this.handleCancelAction}
                    handleRowClick={this.handleRowClick}
                    handleTableDeleteAction={this.handleTableDeleteAction}
                    handleTableClearAction={this.handleTableClearAction}
                    handleFilterRowClick={this.handleFilterRowClick}
                    paginationVisible={true}
                    disablePagination={lastPage}
                    onChangePageTable={(isError || this.state.rowIndex != null ) ? this.handleFilterRowClick : this.onChangePageTable}
                    isEnableSave={isEnableSave}
                    isEnableCancel={isEnableCancel}
                    isError={isError}
                    highlightField={['sno']}
                    sFields={sFields}
                    sValues={sValues}
                />
                     <FieldFilter
                        open={fieldFilter}
                        handleOnClose={this.handleFieldFilterOnClose}
                        handleOnChange={this.handleFieldFilterOnChange}
                        handleOnSave={this.handleFieldFilterOnSave}
                        columnsMap={columnsMap}
                        visibleColumns={visibleColumns}
                        disabledCols={defaultCols}
                    />
                    <AlertDialog 
                        open={isAddOpen}
                        saveDisable={this.state.checkedData.length > 0 ? false : true}
                        saveText={"Add"}
                        onSave={this.handleAddSiteActionClick}
                        onClose={() => { this.setState({ isAddOpen: false, checkedData: [] })  }}
                    >
                    <ContentWrapper
                        title={"Add Sites"}
                        pageName ={'Insepection'}
                        needPadding={true}
                        pageAccess={['CLEAR']}
                        handleClearFilterActionClick={() => {
                            this.setState({tableLoader:true})
                            this.props.siteSearchActions({sFields: [], sValues: []})
                            this.props.getSites(1, globalVariable.tableRowSizeInspectionSite, siteLogs.page.orderBy, siteLogs.page.order, sFields, sValues, ()=>{
                                setTimeout(() => {
                                    this.setState({tableLoader:false});
                                }, 1000);
                            });
                        }}
                    >
                        <TableComponent  
                                header = {siteLogs.page.fieldFilters.headerCols}
                                page = {siteLogs.page.skip}
                                rowsPerPage = {siteLogs.page.limit}
                                noDataErrorMessage="No Records Found"
                                count = {siteLogs.page.count}
                                search = {siteLogs.page.fieldFilters.searchCols}
                                data = {this.state.siteData}
                                field= {siteLogs.page.fieldFilters.fieldCols}
                                paginationVisible={true}
                                sFields={siteSearchData.sFields}
                                sValues={siteSearchData.sValues}
                                isCheckBoxLoading= {this.state.isCheckBoxLoading}
                                showLoading={true}
                                isLoading ={this.state.tableLoader}
                                checkBoxVisible={true}
                                onInputChangeRequest={this.onInputSiteChangeRequest}
                                onSortRequest={this.onSiteSortRequest}
                                order={siteLogs.page.order}
                                orderBy={siteLogs.page.orderBy}
                                handleCheckBoxAction = {this.handleCheckBoxAction}
                                onChangePageTable={this.onChangePageTableAllSites}
                                shiftFields={true}
                            />
                            <PageLoader show={this.state.tableLoader}/>
                    </ContentWrapper>
                  
                    </AlertDialog>
                    { this.state.isSnackVisible && 
                    <SnackBar 
                         type={'error'}
                         style={{ top: '50px',zIndex:'9999999'}}
                         message={this.state.errorMsg}
                         onClose={() => {
                             this.setState({ 
                                isSnackVisible : false
                              });
                         }}
                    />}
                    <DeleteDialog 
                     open={isPageOpen}
                     content={"You have unsaved inspection details are you sure you leave the page?"}
                     handleDeleteAction={this.handleDeleteAction}
                     handleDeleteOnCloseAction={ () => { this.setState({ isPageOpen: false  }) } }
                    />
                    <PageLoader show={this.state.isLoading}/>
                </div>
             </ContentWrapper>
            </>
        )
    }
}

AutoComplete.defaultProps = {
    suggestions: [],
    defaultValue: '',
    name: '',
    handleChange: () => {},
    error: false
}

/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const columnsData = state.configuration.fieldFilter['monitoringCycle'] || {};
    const searchData = state.search['monitoringCycle'] || { sFields: [], sValues: [] };
    const siteSearchData = state.search['siteAll'] || { sFields: [], sValues: [] };
    return {
        monitoringCycle : state.monitoringCycle,
        siteLogs : state.allSiteLogs,
        monitoringType: state.configuration.configurations[configurationConstants.monitoringType] || [],
        columnsMap: columnsData.columnsMap || {},
        defaultCols: columnsData.defaultCols || [],
        visibleColumns: columnsData.visibleColumns || [],
        searchData,
        siteSearchData
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
       getAll: (skip, limit, orderBy, order, sFields, sValues) => dispatch(monitoringCycleActions.getMonitoringCycleLogs(skip, limit, orderBy, order, sFields, sValues)),
       getConfiguration: (type) => dispatch(confActions.getConfiguration(type)),
       getFieldFilter: (table) => dispatch(confActions.getFieldFilter(table)),
       changeFieldFilter: (table, visibleColumns) => dispatch(confActions.changeFieldFilter(table, visibleColumns)),
       updateFieldFilter: (table, visibleColumns, clback) => dispatch(confActions.updateFieldFilter(table, visibleColumns, clback)),
       create: (data) => dispatch(monitoringCycleActions.create(data)),
       change: (data) => dispatch(monitoringCycleActions.changeState(data)),
       changeTemp: (data) => dispatch(monitoringCycleActions.changeTempState(data)),
       changeSiteData: (data) => dispatch(monitoringCycleActions.changeSiteState(data)),
       changeCheckedData: (data) => dispatch(monitoringCycleActions.changeCheckStateData(data)),
       searchActions: (data) => dispatch(searchActions.monitoringCycle(data)),
       siteSearchActions: (data) => dispatch(searchActions.siteAllLogs(data)),
       duplicateCheck: (data, clbk) => dispatch(monitoringCycleActions.checkDuplicate(data, clbk)),
       getSites: (skip, limit, orderBy, order, sFields, sValues,clback) => dispatch(monitoringCycleActions.getSiteLogs(skip, limit, orderBy, order, sFields, sValues,clback)),
       alert : (msg) => dispatch(alertActions.error(msg)),
       clear: (id,clback) => dispatch(monitoringCycleActions.clear(id, clback)),
       download: (data) => dispatch(monitoringCycleActions.download(data)),
       getFunderList: () => dispatch(monitoringCycleActions.getFunderList()),
       clearDuplicate: () => dispatch(monitoringCycleActions.clearDuplicate()),
       changePageDetails: (pageDetails) => dispatch(monitoringCycleActions.changePageCount(pageDetails))
    }
};

/**
 * Inspection Component
 */

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(Inspection));



